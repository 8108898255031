import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import MenuGlobal from '../components/menu'
import Slogan from '../components/slogan3'
import Team from '../components/about/team'
import TeamMobile from '../components/about/teamMobile'
import UniversoIn from '../components/about/universoin'
import EffectCards from '../components/about/effectSlideCards'

import '../sass/about.scss';

const AboutPage = ({ location, data }) => {

  const breakpoints = useBreakpoint();
  const publicsUrl = data.allFile

  const { t } = useTranslation()

  const dataGlobal = t("global", { returnObjects: true })
  const dataAbout = t("about", { returnObjects: true })

  return (
    <Layout mobile={useBreakpoint().mobile} post={null} data={dataGlobal} recruitment page={dataAbout.page}>
      <Seo seo={dataAbout.seo} />


      {breakpoints.mobile ?
        <>
          <MenuGlobal video src="https://res.cloudinary.com/dersmcqwc/video/upload/v1713192460/Videos/Sobre_n%C3%B3s_Mobile_df36qv.mp4"
            global={dataGlobal} textstatic={dataAbout} mobile centered={true}
            location={location} />
          <SmoothScroll>

            <Slogan data={dataAbout.firstsloganmobile} index={0} mobile />

            <EffectCards data={dataAbout.destaques} publicsUrl={publicsUrl.edges} outSrc />

            <TeamMobile data={dataAbout.team} />

            <UniversoIn data={dataAbout.universoin} mobile />

            {/** <TestimoniesMobile data={dataAbout.testimonies} />*/}

            <div className="container-scroll-heigth bg-black" />

          </SmoothScroll>
        </>
        :
        <>
          <MenuGlobal video src="https://res.cloudinary.com/dersmcqwc/video/upload/v1713192466/Videos/Sobre_N%C3%B3s_Desktop_h52lw3.mp4"
            global={dataGlobal} textstatic={dataAbout}
            location={location} />
          <SmoothScroll>

            <Slogan data={dataAbout.firstslogan} index={0} />

            <EffectCards data={dataAbout.destaques} publicsUrl={publicsUrl.edges} outSrc />

            <Team data={dataAbout.team} />

            <UniversoIn data={dataAbout.universoin} />

            {/**<Testimonies data={dataAbout.testimonies} />*/}

            <div className="container-scroll-heigth bg-black" />

          </SmoothScroll>
        </>

      }

    </Layout >
  )
}

export default AboutPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "homepage", "about"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
    allFile {
      edges {
        node {
          relativePath
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`