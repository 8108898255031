import * as  React from "react";

const universoin = ({ data, mobile }) => {

    return (
        <div className="text-center m-auto full-width bg-black pt-2">
            <div className="container-margin">
                <div className="grid container-universo-in text-left">
                    <div className="mb-auto mt-auto ">
                        <p className={mobile ? "white  IBlack  text-center" : "white  IBlack text-justify"}>
                            {data.title.text.map((item, i) => (
                                <span key={'universoIn'+i} className="fs-2 ISemiBold">
                                    {item.includes(data.title.blue) ?
                                        <span className="blue mb-1 uppercase">{item}</span>
                                        :
                                        <span className="mb-1 uppercase">{item}</span>
                                    }
                                </span>))}
                                <br/>
                                <br/>
                            <span className="white fs-6 ILightBeta">
                            {data.text}
                            </span>
                        </p>
                    </div>
                    <div>
                        <iframe
                            src={data.src}
                            title={data.alt}
                            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            className="universoin-video "
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}
export default universoin;